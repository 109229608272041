<template>
    <el-main>
        <div class="searchdiv">
            <div class="searchform">
                <el-input v-model="searchinfo.title" :placeholder="$t('flash.title')" :label="$t('flash.title')" clearable></el-input>
                <el-select v-model="searchinfo.lang" :placeholder="$t('flash.chooselanguage')">
                    <template v-for="language in langlist">
                        <el-option :key="language.lang"
                                   :label="language.name"
                                   :value="language.lang">
                        </el-option>
                    </template>
                </el-select>
                <el-button type="primary" @click="dosearch()" icon="el-icon-search" :disabled="isbusy" :loading="isbusy">{{$t('global.search')}}</el-button>
            </div>
            <div>
                <el-button type="primary" @click="adduser('userform')" icon="el-icon-plus">{{ $t('global.add') }}
                  {{ $t('flash.flash') }}</el-button>
            </div>
        </div>
        <div class="pagecontent">
            <el-table ref="multipleTable"
                      :data="list"
                      tooltip-effect="dark"
                      style="width: 100%">
                <el-table-column
                        :label="$t('flash.title')"
                        width="120" prop="title">
                </el-table-column>
                <el-table-column
                    :label="$t('flash.language')"
                    width="120" prop="name">
                </el-table-column>
                <el-table-column
                        :label="$t('Position')"
                        width="120">
                  <template slot-scope="scope">
                    {{scope.row.typetext}}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('flash.picture')" width="120" prop="picture">
                    <template slot-scope="scope">
                        <el-popover
                                placement="right"
                                title=""
                                trigger="hover">
                            <img :src="scope.row.picture" style="width:400px;"/>
                            <img slot="reference" :src="scope.row.picture" :alt="scope.row.picture" style="max-height: 50px;max-width: 130px">
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="url"
                        :label="$t('flash.url')"
                        >
                </el-table-column>
                <el-table-column
                        :label="$t('flash.sort')"
                        width="120" prop="sort">
                </el-table-column>
                <el-table-column
                        :label="$t('global.state')"
                        width="120" prop="status">
                    <template slot-scope="scope">
                        <el-link :type="scope.row.status?'success':'danger'"
                                 @click="setenable(scope.row)">{{ scope.row.status?$t('global.enable'):$t('global.disable') }}</el-link>
                    </template>
                </el-table-column>
                <el-table-column
                        :label="$t('flash.created_at')"
                        width="170" prop="created_at">
                </el-table-column>
                <el-table-column :label="$t('global.action')" fixed="right">
                    <template slot-scope="scope">
                        <el-link class="optlink"  @click="edituser(scope.row)">{{$t('global.edit')}}</el-link>
                        <el-link class="optlink"  @click="deluser(scope.row)">{{$t('global.delete')}}</el-link>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    background
                    @size-change="SizeChange"
                    @current-change="getlist"
                    :current-page="searchinfo.page"
                    :page-sizes="PAGE.pageSizes"
                    :page-size="searchinfo.pagesize"
                    :layout="PAGE.layout"
                    :total="totalRows">
            </el-pagination>
        </div>
        <el-dialog :title="userformdata.id?$t('flash.editFlash'):$t('flash.addFlash')" :visible.sync="isshowdialog" :close-on-click-modal="false">
            <el-form :model="userformdata" :rules="rules" ref="userform" :show-message="false">
                <el-form-item :label="$t('flash.title')" label-width="120px" prop="title" required>
                    <el-input v-model="userformdata.title" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Position')" label-width="120px" prop="type_id" required>
                  <el-select v-model="userformdata.type_id" :placeholder="$t('Position')">
                    <el-option v-for="(onetype, oidx) in typelist" :key="oidx" :label="onetype.text" :value="onetype.value">
                      {{onetype.text}}
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('flash.language')" label-width="120px" prop="lang" required>
                    <el-select v-model="userformdata.lang" :placeholder="$t('flash.chooselanguage')">
                        <template v-for="language in langlist">
                            <el-option :key="language.id"
                                       :label="language.name"
                                       :value="language.lang">
                            </el-option>
                        </template>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('flash.picture')" label-width="120px" prop="picture" required>
                    <el-upload
                            class="avatar-uploader"
                            :action="uploadurl"
                            :headers="uploadheader"
                            :show-file-list="false"
                            :on-success="uploadsuccess"
                            :before-upload="beforeupload">
                        <img v-if="userformdata.picture" :src="userformdata.picture" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item :label="$t('flash.url')" label-width="120px" prop="url" required>
                    <el-input v-model="userformdata.url" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item :label="$t('flash.sort')" label-width="120px" prop="sort">
                    <el-input v-model="userformdata.sort"></el-input>
                </el-form-item>
                <el-form-item :label="$t('flash.status')" label-width="120px" required>
                    <el-switch v-model="userformdata.status" :active-value="1" :inactive-value="0" :active-text="$t('global.yes')" :inactive-text="$t('global.no')"></el-switch>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">{{$t('global.cancel')}}</el-button>
                <el-button type="primary" @click="dosubmit('userform')" :disabled="isbusy" :loading="isbusy">{{$t('global.submit')}}</el-button>
            </div>
        </el-dialog>
    </el-main>
</template>

<script>
    // @ is an alias to /src

    export default {
        name: 'user',
        components: {},
        data() {
            return {
                searchinfo: {
                    page:1,
                    pagesize: this.PAGE.pageSize,
                    title: '',
                    lang:''
                },
                list: [],
                multipleSelection: [],
                totalRows: 0,
                defaultuserinfo: {
                    id: 0,
                    type_id: '',
                    title: '',
                    lang: '',
                    url: '',
                    picture: '',
                    sort: 0,
                    status: 1,
                },
                userformdata: {},
                rules: {
                    title: [
                        { min: 2, message: '幻灯片名称必须在2个字符以上', trigger: 'blur' }
                    ],
                },
                isshowdialog: false,
                uploadurl: process.env.VUE_APP_API_ROOT+'/system/upload/images?storage=signimg',
                uploadheader: {Authorization:this.$store.getters.getToken},
                isbusy: false,
                typelist: [
                  {value: 1, text: 'Index Banner'},
                  {value: 2, text: 'Profile'},
                  {value: 3, text: 'Task List'},
                  {value: 4, text: 'Index Center'},
                ],
            }
        },
        methods: {
            SizeChange(val){
                this.searchinfo.pagesize=val;
                this.getlist(1);
            },
            edituser(item){
                this.userformdata=null;
                item.password=undefined;
                this.userformdata = this.cloneData(item);
                this.isshowdialog=true;
            },
            adduser(formName){
                this.userformdata = this.cloneData(this.defaultuserinfo);
                this.isshowdialog=true;
                this.$nextTick(()=>{
                    this.$refs[formName].resetFields();
                });
            },
            async dosearch(){
                this.getlist(1);
            },
            async getlist(page){
                this.searchinfo.page=page||this.searchinfo.page;
                this.isbusy = true;
                let res= await this.httpget('/system/flash/index',this.searchinfo);
                if(res.code===0){
                    this.list=res.data.data;
                    this.list.forEach((oneflash)=>{
                      let idx = this.typelist.findIndex((item)=>{
                        return item.value == oneflash.type_id;
                      })
                      if(idx!==-1){
                        oneflash.typetext = this.typelist[idx].text;
                      }else{
                        oneflash.typetext = '';
                      }
                    })
                    this.totalRows = res.data.total;
                    this.isbusy = false;
                } else {
                    this.isbusy = false;
                }

            },
            dosubmit(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.suresubmit();
                    } else {
                        return false;
                    }
                });
            },
            async suresubmit(){
                this.isbusy = true;
                let res= await this.httppost('/system/flash/save',this.userformdata);
                if(res.code===0){
                    this.$message({type: 'success', message: res.msg});
                    this.getlist(1);
                    this.userformdata={};
                    this.isshowdialog=false;
                    this.isbusy = false;
                }else{
                    this.isbusy = false;
                    this.$message({type: 'error', message: res.msg});
                }
            },
            async setenable(item){
                this.$confirm(this.$t('global.areyousure')).then( async () => {
                    this.isbusy = true;
                    let res= await this.httpput('/system/flash/setenable',{id:item.id});
                    if(res.code===0){
                        this.$message({type: 'success', message: res.msg});
                        let idx=this.list.findIndex((value) => {
                            return value.id === item.id
                        })
                        if(idx!==-1){
                            this.list[idx].status = Math.abs(this.list[idx].status-1);
                        }
                        this.isbusy = false;
                    }else{
                        this.isbusy = false;
                        this.$message({type: 'error', message: res.msg});
                    }
                }).catch(() => {
                    this.isbusy = false;
                    this.$message({type: 'info', message: 'Canceled'});
                });
            },
            async deluser(item){
                this.$confirm(this.$t('global.areyousure')).then( async () => {
                    this.isbusy = true;
                    let res= await this.httpput('/system/flash/delete',{id:item.id});
                    if(res.code===0){
                        this.$message({type: 'success', message: res.msg});
                        this.getlist();
                    }else{
                        this.$message({type: 'error', message: res.msg});
                    }
                    this.isbusy = false;
                }).catch(() => {
                    this.isbusy = false;
                    this.$message({type: 'info', message: 'Canceled'});
                });
            },
            uploadsuccess(res){
                if(res.code===0){
                    if(res.data[0]
                        && res.data[0].indexOf(process.env.VUE_APP_IMG_DOMAIN)===-1
                        && res.data[0].indexOf('http://')===-1){
                        res.data[0] = process.env.VUE_APP_IMG_DOMAIN + '/storage/' + res.data[0];
                    }
                    this.userformdata.picture = res.data[0];
                }else{
                    this.$message({type: 'error', message: res.msg});
                }
            },
            beforeupload(file) {
                const isJPG = (file.type === 'image/jpeg'||file.type === 'image/png' || file.type === 'image/gif');
                const isLt2M = file.size / 1024 / 1024 < 8;
                if (!isJPG) {
                    this.$message.error('Upload avatar pictures can only be in JPG or PNG format!');
                }
                if (!isLt2M) {
                    this.$message.error('Upload avatar image size can\'t exceed 2MB!');
                }
                return isJPG && isLt2M;
            },
        },
        mounted() {
            this.getlist(1);
            this.uploadurl = process.env.VUE_APP_API_ROOT+'/system/upload/images?storage=signimg';
        },
        computed: {
            langlist: {
              get: function () {
                return this.$store.getters.getLanglist;
              }
            },
        },
    }
</script>

<style>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
    }
    .avatar {
        width: 100px;
        height: 100px;
        display: block;
    }
</style>
